import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { BrandMatchWithSubStep } from '../../types/navigation';
import { getCorrectStep } from '../../utils/utils';
import {
  OnboardingStepShippingCountry,
  OnboardingStepShippingCountryProps,
} from './onboarding-step-shipping/onboarding-step-shipping-country';
import {
  OnboardingStepShippingDeliveryService,
  OnboardingStepShippingDeliveryServiceProps,
} from './onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import {
  OnboardingStepShippingShippingDelay,
  OnboardingStepShippingShippingDelayProps,
} from './onboarding-step-shipping/onboarding-step-shipping-shipping-delay';

export type OnboardingStepShippingProps = {
  goToNextStep: () => void;
};

export const OnboardingStepShipping: React.FC<OnboardingStepShippingProps> = ({
  goToNextStep,
}: OnboardingStepShippingProps) => {
  const subSteps = [
    OnboardingStepShippingCountry,
    OnboardingStepShippingDeliveryService,
    OnboardingStepShippingShippingDelay,
  ];

  const navigate = useNavigate();
  const params = useParams<BrandMatchWithSubStep>();

  const [subStep, setSubStep] = useState(
    getCorrectStep(params.substep, subSteps.length - 1)
  );

  useUpdateEffect(() => {
    setSubStep(getCorrectStep(params.substep, subSteps.length - 1));
  }, [params.substep]);

  const goToNextSubStepOrStep = () => {
    if (subStep === subSteps.length - 1) {
      goToNextStep();
    } else {
      navigate(
        `/${params.brandId}/onboarding/${params.step ?? ''}/${subStep + 1}${location.search}`
      );
      setSubStep(subStep + 1);
    }
  };

  const Step = subSteps[subStep] as React.ElementType<
    | OnboardingStepShippingCountryProps
    | OnboardingStepShippingDeliveryServiceProps
    | OnboardingStepShippingShippingDelayProps
  >;

  return (
    <div className="w-full">
      <Step goToNextSubStepOrStep={goToNextSubStepOrStep} />
    </div>
  );
};

OnboardingStepShipping.displayName = 'OnboardingStepShipping';
