import React, { useId } from 'react';
import ReactTextareaAutosize, {
  TextareaAutosizeProps as ReactTextareaAutosizeProps,
} from 'react-textarea-autosize';

import cn from '@appchoose/cn';

export type TextareaAutosizeProps = {
  /**
   * Define the id of the input
   */
  id?: string;
} & ReactTextareaAutosizeProps;

export const TextareaAutosize = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>(
  (
    { id, className, ...props }: TextareaAutosizeProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    const uniqueId = useId();
    const textareaId = id ?? uniqueId;

    return (
      <div className="relative w-full text-gray-500 transition-colors focus-within:text-gray-900">
        <ReactTextareaAutosize
          id={textareaId}
          ref={ref}
          className={cn(
            'form-input block w-full rounded border border-gray-500 p-2 text-sm leading-5.5 text-gray-900 placeholder-gray-500 outline-none transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-300 disabled:placeholder-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600',
            className
          )}
          minRows={1}
          maxRows={4}
          {...props}
        />
      </div>
    );
  }
);

TextareaAutosize.displayName = 'TextareaAutosize';
