import { useRecoilState } from 'recoil';

import { brandState } from '../../../stores/brand';
import {
  useSearchCouriersBySupportedCountriesQuery,
  useUsedCouriersQuery,
} from '../../../types/generated-new';
import {
  CountryISO3,
  getCountryISO2,
  getCountryISO3,
} from '../../../utils/country';
import { SelectedCourier } from './onboarding-step-shipping-delivery-service';

export const useVisibleCouriers = () => {
  const [brand] = useRecoilState(brandState);

  const { refetch: refetchUsedCouriers } = useUsedCouriersQuery(
    {},
    {
      select: (data) =>
        data.usedCouriers.map((courier) => ({
          ...courier,
          supportedCountries: courier.supportedCountries.map(
            (country) => getCountryISO2(country) ?? ''
          ),
        })),
      enabled: false,
    }
  );
  const { refetch: refetchSearchCouriersBySupportedCountries } =
    useSearchCouriersBySupportedCountriesQuery(
      {
        input: {
          countryCodeIso3:
            getCountryISO3((brand?.shipping_country as CountryISO3) ?? 'FR') ??
            '',
        },
      },
      {
        select: (data) =>
          data.searchCouriersBySupportedCountries.map((courier) => ({
            ...courier,
            supportedCountries: courier.supportedCountries.map(
              (country) => getCountryISO2(country) ?? ''
            ),
          })),
        enabled: false,
      }
    );

  const FR_DEFAULT_SHIPPING_SERVICES = [
    {
      slug: 'la-poste-colissimo',
      name: 'La Poste',
      logoUrl:
        'https://assets.aftership.com/couriers/svg/la-poste-colissimo.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'colissimo',
      name: 'Colissimo',
      logoUrl: 'https://assets.aftership.com/couriers/svg/colissimo.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'gls',
      name: 'GLS',
      logoUrl: 'https://assets.aftership.com/couriers/svg/gls.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'exapaq',
      name: 'DPD France (formerly exapaq)',
      logoUrl: 'https://assets.aftership.com/couriers/svg/exapaq.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'colis-prive',
      name: 'Colis Privé',
      logoUrl: 'https://assets.aftership.com/couriers/svg/colis-prive.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'chronopost-france',
      name: 'Chronopost France',
      logoUrl:
        'https://assets.aftership.com/couriers/svg/chronopost-france.svg',
      supportedCountries: ['FR'],
    },
    {
      slug: 'fedex',
      name: 'FedEx®',
      logoUrl: 'https://assets.aftership.com/couriers/svg/fedex.svg',
      supportedCountries: ['FR'],
    },
  ];
  const US_DEFAULT_SHIPPING_SERVICES = [
    {
      slug: 'usps',
      name: 'USPS',
      logoUrl: 'https://assets.aftership.com/couriers/svg/usps.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'ups',
      name: 'UPS',
      logoUrl: 'https://assets.aftership.com/couriers/svg/ups.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'fedex',
      name: 'FedEx®',
      logoUrl: 'https://assets.aftership.com/couriers/svg/fedex.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'dhl',
      name: 'DHL Express',
      logoUrl: 'https://assets.aftership.com/couriers/svg/dhl.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'chitchats',
      name: 'Chit Chats',
      logoUrl: 'https://assets.aftership.com/couriers/svg/chitchats.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'dhl-global-mail',
      name: 'DHL eCommerce Solutions',
      logoUrl: 'https://assets.aftership.com/couriers/svg/dhl-global-mail.svg',
      supportedCountries: ['US'],
    },
    {
      slug: 'canada-post',
      name: 'Canada Post',
      logoUrl: 'https://assets.aftership.com/couriers/svg/canada-post.svg',
      supportedCountries: ['US'],
    },
  ];

  const getVisibleCouriers = async () => {
    const visibleCouriers: SelectedCourier[] = [];
    const usedCouriers = (await refetchUsedCouriers()).data ?? [];

    let defaultShippingServices = FR_DEFAULT_SHIPPING_SERVICES;
    if (brand?.shipping_country === 'US')
      defaultShippingServices = US_DEFAULT_SHIPPING_SERVICES;
    if (brand?.shipping_country !== 'FR' && brand?.shipping_country !== 'US') {
      defaultShippingServices =
        (await refetchSearchCouriersBySupportedCountries()).data?.slice(0, 7) ??
        [];
    }

    defaultShippingServices.forEach((defaultShippingService) => {
      const isSelected = !!usedCouriers.find(
        (courier) => courier.slug === defaultShippingService.slug
      );
      visibleCouriers.push({
        ...defaultShippingService,
        selected: isSelected,
      });
    });
    usedCouriers.forEach((usedCourier) => {
      if (
        defaultShippingServices.find(
          (defaultShippingService) =>
            defaultShippingService.slug === usedCourier.slug
        )
      ) {
        return;
      }
      visibleCouriers.push({
        ...usedCourier,
        selected: true,
      });
    });

    return visibleCouriers;
  };

  return { getVisibleCouriers };
};
