export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeAllSpaces = (value: string) =>
  value.replace(/\s/g, '').trim();

export const pad = (num: number, size: number) => {
  let numStr = num.toString();
  while (numStr.length < size) numStr = '0' + numStr;
  return numStr;
};

// Shopify prefixes can be found here: https://shopify.dev/changelog/length-of-the-shopify-access-token-is-increasing#:~:text=The%20length%20of%20newly%20generated,tokens%20generated%20after%20this%20date
export const isShopifyTokenValid = (token: string) => {
  if (token.length !== 38) return false;
  if (
    token.length === 38 &&
    !(
      token.startsWith('shpat_') ||
      token.startsWith('shpca_') ||
      token.startsWith('shppa_')
    )
  )
    return false;

  return true;
};

export const isShopifySecretValid = (secret: string) => {
  if (secret.length !== 32 && secret.length !== 38) return false;
  if (secret.length === 38 && !secret.startsWith('shpss_')) return false;

  return true;
};

export const formatShopifyURL = (string: string) => {
  let url: string;
  try {
    const host = new URL(string).host;
    url = host;
  } catch {
    url = string;
  }
  return url;
};

export const formatList = (
  listOfStrings: string[],
  locale: string,
  options?: Intl.ListFormatOptions
) => {
  const formatter = new Intl.ListFormat(locale, options);
  return formatter.format(listOfStrings);
};

export const formatListToParts = (
  listOfStrings: string[],
  locale: string,
  options?: Intl.ListFormatOptions
) => {
  const formatter = new Intl.ListFormat(locale, options);
  return formatter.formatToParts(listOfStrings);
};
