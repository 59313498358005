import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  ButtonDropdown,
  ButtonDropdownButton,
  ButtonDropdownMenuContent,
  ButtonDropdownMenuItem,
  ButtonDropdownMenuTrigger,
} from '@appchoose/button-dropdown';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';

import { Desktop } from '../../components/icons/desktop';
import { ShopifyPluggedIcon } from '../../components/icons/shopify-plugged/shopify-plugged';
import { useProductStockUpdatedSubscription } from '../../hooks/use-product-stock-updated-subscription';
import { brandState } from '../../stores/brand';
import { StockProduct, productsState } from '../../stores/product';
import { activeSaleIdState } from '../../stores/sales';
import { ProductsSort, useStockQuery } from '../../types/generated';
import { FileExtension, useStocksExportQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { StockProductList } from './stock-product-list';
import { StockSearch } from './stock-search';

import './stock-screen.scss';

export const StockScreen: React.FC = () => {
  const brand = useRecoilValue(brandState);
  const { t } = useTranslation();

  const activeSaleId = useRecoilValue(activeSaleIdState);
  const [products, setProducts] = useRecoilState(productsState);

  const pageRef = useRef<HTMLDivElement>(null);

  useTitle(`${brand?.name ?? ''} ~ Stock`);

  useProductStockUpdatedSubscription();

  const [value, setValue] = useState('export-csv');
  const menuOptions = [
    {
      label: t('stock.export.export_stocks_as_csv'),
      value: 'export-csv',
      onClick: async () => {
        const result = await refetchStocksExport();
        if (result.data) {
          if (result.data.stocksExport.fileUrl) {
            window.open(result.data.stocksExport.fileUrl, '_self');
            toast.success(result.data.stocksExport.fileUrl);
          } else {
            toast.success(t('stock.export.no_stock'));
          }
        }
        track('ExportStock', {
          format: 'csv',
        });
      },
    },
    {
      label: t('stock.export.export_stocks_as_xls'),
      value: 'export-xls',
      onClick: async () => {
        const result = await refetchStocksExport();
        if (result.data) {
          if (result.data.stocksExport.fileUrl) {
            window.open(result.data.stocksExport.fileUrl, '_self');
            toast.success(result.data.stocksExport.fileUrl);
          } else {
            toast.success(t('stock.export.no_stock'));
          }
        }
        track('ExportStock', {
          format: 'xls',
        });
      },
    },
  ];

  const selectedMenuOption = menuOptions.find(
    (option) => option.value === value
  );
  const { isFetching: loadingStocksExport, refetch: refetchStocksExport } =
    useStocksExportQuery(
      {
        saleId: activeSaleId ?? '',
        input: {
          fileExtension:
            value === 'export-csv' ? FileExtension.Csv : FileExtension.Xlsx,
        },
      },
      {
        enabled: false,
      }
    );

  const { data: stockData, isLoading: loadingStock } = useStockQuery(
    {
      saleId: activeSaleId ?? '',
      sort: ProductsSort.Name,
    },
    {
      enabled: !!activeSaleId,
      select: (data) =>
        data.products?.filter(
          (product): product is StockProduct => !!product
        ) ?? [],
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (stockData) {
      setProducts(stockData);
    }
  }, [stockData]);

  const hasProducts = products && products.length > 0;
  const isShopifySynced = brand?.shopify?.shop && brand?.shopify.isStockLive;

  return (
    <main
      ref={pageRef}
      className="stock relative flex-1 overflow-y-auto focus:outline-none"
    >
      <div className="m-4 md:m-10">
        <header className="flex items-center justify-between">
          <div className="flex gap-6">
            <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
              {t('sidebar.stock')}
            </h2>

            {isShopifySynced ? (
              <div className="flex items-center gap-1 self-center rounded bg-gray-50 px-2 py-1 font-semibold text-gray-700">
                <ShopifyPluggedIcon
                  title="Shopify"
                  className="size-[1.125rem]"
                />
                <span className="hidden text-sm sm:inline">
                  {t('stock.synced_with_shopify')}
                </span>
              </div>
            ) : null}
          </div>
          {hasProducts ? (
            <ButtonDropdown value={value} onValueChange={setValue}>
              <ButtonDropdownButton
                type="button"
                onClick={selectedMenuOption?.onClick}
                disabled={loadingStocksExport}
              >
                {selectedMenuOption?.label}
              </ButtonDropdownButton>
              <ButtonDropdownMenuTrigger disabled={loadingStocksExport}>
                <Icon icon="dropdown" />
              </ButtonDropdownMenuTrigger>
              <ButtonDropdownMenuContent>
                {menuOptions.map((option) => (
                  <ButtonDropdownMenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </ButtonDropdownMenuItem>
                ))}
              </ButtonDropdownMenuContent>
            </ButtonDropdown>
          ) : null}
        </header>
        {hasProducts ? (
          brand?.shopify?.shop && isShopifySynced ? (
            <p className="mb-10 mt-4 flex items-center space-x-2 text-sm text-gray-700">
              <Icon icon="alertCircle" className="shrink-0" />
              <div className="flex flex-wrap gap-1">
                {t('stock.info_shopify')}
                <a
                  href={`https://${brand?.shopify.shop}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="flex items-center gap-0.5 font-semibold text-green-900"
                >
                  <span>{brand?.shopify.shop}</span>
                  <Icon icon="externalLinkSimple" />
                </a>
              </div>
            </p>
          ) : (
            <p className="mb-10 mt-4 flex items-center space-x-2 text-sm text-gray-700">
              <Icon icon="information" className="shrink-0" />
              <span>{t('stock.info')}</span>
            </p>
          )
        ) : (
          <div className="mt-10"></div>
        )}

        <section>
          {loadingStock ? (
            <div className="mb-8 flex justify-center">
              <Loader className="size-8" />
            </div>
          ) : !hasProducts ? (
            <div className="flex max-w-[55rem] flex-col justify-between space-x-6 rounded border border-gray-200 bg-gray-50 p-6 md:flex-row">
              <div className="flex w-full items-center space-x-4 pb-4 md:pb-0">
                <Desktop className="size-10" />

                <div className="flex w-full flex-col gap-1">
                  <div className="text-sm font-semibold text-gray-700">
                    {t('stock.empty_title')}
                  </div>
                  <p className="text-sm text-gray-700">
                    {t('stock.empty_text')}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="mb-6">
                <div className="relative rounded-md text-gray-500 transition-all focus-within:text-gray-900">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <Icon icon="search" />
                  </div>
                  <StockSearch />
                </div>
              </div>

              <StockProductList pageRef={pageRef} />
            </>
          )}
        </section>
      </div>
    </main>
  );
};

StockScreen.displayName = 'StockScreen';
