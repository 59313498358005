import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Checkbox from '@appchoose/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';

import { track } from '../../utils/analytics';

export type OnboardingStepCommitmentsProps = {
  goToNextStep: () => void;
};

type OnboardingStepCommitmentsForm = {
  acceptCommitments: boolean;
};

export const OnboardingStepCommitments: React.FC<
  OnboardingStepCommitmentsProps
> = ({ goToNextStep }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    goToNextStep();
    track('EndOnboarding', {});
  };

  const form = useForm<OnboardingStepCommitmentsForm>({
    mode: 'onTouched',
    defaultValues: {
      acceptCommitments: false,
    },
  });

  const commitments = [
    {
      key: '1',
      icon: 'clock' as const,
      title: t('onboarding.commitments.commitment_1'),
      detail: t('onboarding.commitments.commitment_1_detail'),
    },
    {
      key: '2',
      icon: 'package' as const,
      title: t('onboarding.commitments.commitment_2'),
      detail: t('onboarding.commitments.commitment_2_detail'),
    },
    {
      key: '3',
      icon: 'complains' as const,
      title: t('onboarding.commitments.commitment_3'),
      detail: t('onboarding.commitments.commitment_3_detail'),
    },
    {
      key: '4',
      icon: 'closeCircle' as const,
      title: t('onboarding.commitments.commitment_4'),
      detail: t('onboarding.commitments.commitment_4_detail'),
    },
  ];

  return (
    <section className="w-full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-10">
            <div className="mt-8 sm:mt-20">
              <h2 className="text-2xl font-bold sm:text-3.5xl">
                {t('onboarding.commitments.title')}
              </h2>
            </div>

            <ul className="grid grid-cols-1 gap-10 sm:grid-cols-2">
              {commitments.map((commitment) => (
                <li
                  key={commitment.key}
                  className="flex flex-col items-start gap-3"
                >
                  <div className="rounded border border-gray-300 bg-[#FBFBFB] p-2">
                    <Icon
                      icon={commitment.icon}
                      className="size-6 text-gray-600"
                    />
                  </div>
                  <div className="flex flex-col gap-0.5">
                    <p className="font-semibold text-gray-900">
                      {commitment.title}
                    </p>
                    <p className="text-sm leading-5.5 text-gray-700">
                      {commitment.detail}
                    </p>
                  </div>
                </li>
              ))}
            </ul>

            <FormField
              control={form.control}
              name="acceptCommitments"
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <FormItem>
                  <label
                    className="flex items-center gap-3 rounded border border-gray-300 bg-[#FBFBFB] p-6 aria-[invalid='true']:border-[#EDB6B6]"
                    aria-invalid={!!error}
                  >
                    <FormControl>
                      <Checkbox
                        {...field}
                        className="shrink-0"
                        value=""
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <span className="whitespace-pre-line text-sm leading-5.5 text-gray-900">
                      {t('onboarding.commitments.accept_commitments.label')}
                    </span>
                  </label>
                  <FormMessage match="required">
                    {t(
                      'onboarding.commitments.accept_commitments.validation_errors.required'
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
          <div className="mt-8 flex sm:mt-10">
            <Button size="large" type="submit">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </section>
  );
};
