import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useSessionStorage, useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import {
  OnBoardingContactForm,
  OnBoardingContactFormProps,
} from '../../components/onboarding/onboarding-contact-form';
import { OnboardingStepAuth } from '../../components/onboarding/onboarding-step-auth/onboarding-step-auth';
import {
  OnboardingStepBillingInfo,
  OnboardingStepBillingInfoProps,
} from '../../components/onboarding/onboarding-step-billing-info';
import {
  OnboardingStepCommitments,
  OnboardingStepCommitmentsProps,
} from '../../components/onboarding/onboarding-step-commitments';
import {
  OnboardingStepReturnAddress,
  OnboardingStepReturnAddressProps,
} from '../../components/onboarding/onboarding-step-return-address';
import {
  OnboardingStepShipping,
  OnboardingStepShippingProps,
} from '../../components/onboarding/onboarding-step-shipping';
import { OnboardingStepWelcome } from '../../components/onboarding/onboarding-step-welcome';
import { Stepper } from '../../components/stepper/Stepper';
import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import { brandState } from '../../stores/brand';
import { useUpdateSellerMutation } from '../../types/generated';
import { BrandMatch } from '../../types/navigation';
import { getCorrectStep } from '../../utils/utils';
import { Welcome } from '../welcome/welcome';

export const OnboardingScreen: React.FC = () => {
  const [brand, setBrand] = useRecoilState(brandState);
  const { user } = useAuth0();
  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const { brandId = '', step } = useParams<BrandMatch>();
  const [success, setSuccess] = useState(false);
  const [, setInviteCode] = useSessionStorage<string>('inviteCode');

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const steps = [
    {
      label: t('onboarding.welcome.name'),
      component: OnboardingStepWelcome,
    },
    {
      label: t('onboarding.signup.name'),
      component: OnboardingStepAuth,
    },
    {
      label: t('onboarding.shipping.name'),
      component: OnboardingStepShipping,
    },
    {
      label: t('onboarding.contact.name'),
      component: OnBoardingContactForm,
    },
    {
      label: t('onboarding.return_address.name'),
      component: OnboardingStepReturnAddress,
    },
    {
      label: t('onboarding.billing.name'),
      component: OnboardingStepBillingInfo,
    },
    {
      label: t('onboarding.commitments.name'),
      component: OnboardingStepCommitments,
    },
  ];

  const [sanitizedStep, setSanitizedStep] = useState<number>(
    getCorrectStep(step, steps.length - 1)
  );

  useEffect(() => {
    if (queryParams.has('invite-code')) {
      setInviteCode(queryParams.get('invite-code') as string);
    }
  }, []);

  useUpdateEffect(() => {
    setSanitizedStep(getCorrectStep(step, steps.length - 1));
  }, [step]);

  if (brand?.accept_contract === true) {
    if (!queryParams.has('forceOnboardingChasse') || !isInternalUser) {
      return <Navigate to={`/${brandId}/home`} />;
    }
  }

  const goToNextStep = () => {
    if (sanitizedStep === steps.length - 1) {
      onEndOnboarding();
    } else {
      navigate(`/${brandId}/onboarding/${sanitizedStep + 1}${location.search}`);
      setSanitizedStep(sanitizedStep + 1);
    }
  };

  const onEndOnboarding = async () => {
    if (!brand) return;
    setBrand({
      ...brand,
      accept_contract: true,
    });

    await updateSellerMutation({
      updateSeller: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        accept_contract: true,
      },
    });

    setSuccess(true);
  };

  const Step = steps[sanitizedStep].component as React.ElementType<
    | OnboardingStepShippingProps
    | OnBoardingContactFormProps
    | OnboardingStepReturnAddressProps
    | OnboardingStepBillingInfoProps
    | OnboardingStepCommitmentsProps
  >;

  return success ? (
    <Welcome />
  ) : (
    <div className="onboarding mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader brandName={brand?.name ?? ''}>
        <Stepper
          steps={steps}
          currentStep={sanitizedStep}
          className="w-full sm:max-w-[22.5rem]"
        />
      </XChooseHeader>
      <main className="sm:px-[4.75rem]">
        <Routes>
          <Route
            path={':step?/:substep?'}
            element={<Step goToNextStep={goToNextStep} />}
          />
        </Routes>
      </main>
    </div>
  );
};

OnboardingScreen.displayName = 'OnboardingScreen';
