import React from 'react';

import cn from '@appchoose/cn';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

export type ShippingServiceCheckboxProps = CheckboxPrimitive.CheckboxProps & {
  warning: boolean;
};

export const ShippingServiceCheckbox = React.forwardRef<
  HTMLButtonElement,
  ShippingServiceCheckboxProps
>(
  (
    { children, className, ...props }: ShippingServiceCheckboxProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          {
            'border-orange-600 focus:ring-2 focus:ring-gray-900/30':
              !props.disabled &&
              props.warning === true &&
              props.checked === true,
            'border-gray-900 focus:ring-2 focus:ring-gray-900/30':
              !props.disabled &&
              props.warning === false &&
              props.checked === true,
            'border-gray-100 focus:ring-2 focus:ring-gray-900/30':
              !props.disabled && props.checked !== true,
            'cursor-pointer text-gray-900 hover:bg-[#FBFBFB]': !props.disabled,
            'cursor-not-allowed border-gray-300 text-gray-300': props.disabled,
            'group flex items-center rounded border p-3 outline-0 transition duration-300':
              true,
          },
          className
        )}
        {...props}
      >
        {children}
      </CheckboxPrimitive.Root>
    );
  }
);

ShippingServiceCheckbox.displayName = 'OrderCheckbox';
