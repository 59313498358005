import React, { Key, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Combobox, {
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxPopover,
  ComboboxSection,
  HighlightText,
  InputContainer,
} from '@appchoose/combobox';
import Icon from '@appchoose/icon';
import { matchSorter } from 'match-sorter';

import { getCountryOptions } from '../../utils/address-options';

type FlagComboboxProps = {
  defaultValue?: string;
  onSearch?: (value: string) => void;
  onSelect?: (value: string) => void;
};

export type FlagComboboxRef = {
  clearInput: () => void;
};

export const FlagCombobox = React.forwardRef<
  FlagComboboxRef,
  FlagComboboxProps
>(
  (
    { defaultValue, onSearch, onSelect },
    ref: React.ForwardedRef<FlagComboboxRef>
  ) => {
    const { t } = useTranslation();
    const countryOptions = getCountryOptions();
    const [fieldState, setFieldState] = React.useState<{
      inputValue: string;
      selectedKey: string | null;
    }>({
      selectedKey: defaultValue ?? null,
      inputValue:
        countryOptions.find((option) => option.value === defaultValue)?.label ??
        '',
    });

    const matches = useMemo(
      () =>
        matchSorter(countryOptions, fieldState.inputValue, { keys: ['label'] }),
      [fieldState, countryOptions]
    );

    useImperativeHandle(ref, () => ({
      clearInput() {
        onClear();
      },
    }));

    const onSelectionChange = (key: Key | null) => {
      setFieldState({
        inputValue: countryOptions.find((f) => f.value === key)?.label ?? '',
        selectedKey: key as string,
      });
      onSelect?.(key as string);
    };

    const onInputChange = (value: string) => {
      setFieldState(
        (prevState: { inputValue: string; selectedKey: string | null }) => ({
          inputValue: value,
          selectedKey: value === '' ? null : prevState.selectedKey,
        })
      );
      onSearch?.(value);
    };

    const onClear = () => {
      setFieldState({
        selectedKey: '',
        inputValue: '',
      });
    };

    return (
      <Combobox
        selectedKey={fieldState.selectedKey}
        inputValue={fieldState.inputValue}
        onSelectionChange={onSelectionChange}
        onInputChange={onInputChange}
        items={matches}
        className="w-full"
      >
        <InputContainer>
          <div className="absolute inset-y-0 left-0 flex items-center pl-2">
            {fieldState.selectedKey ? (
              countryOptions.find(
                (option) => option.value === fieldState.selectedKey
              )?.leftSection
            ) : (
              <Icon icon="search" />
            )}
          </div>
          <ComboboxInput
            className={cn(`truncate pl-8`, {
              'pl-10 pr-8': fieldState.selectedKey,
            })}
            placeholder={t('country.placeholder')}
          />
          {fieldState.selectedKey ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <button
                onClick={onClear}
                type="button"
                className="text-sm font-semibold"
              >
                <Icon icon="close" />
              </button>
            </div>
          ) : null}
        </InputContainer>

        <ComboboxPopover>
          <ComboboxList
            renderEmptyState={() => (
              <ComboboxEmpty>{t('search.no_country')}</ComboboxEmpty>
            )}
          >
            {matches.length ? (
              <ComboboxSection>
                {matches.slice(0, 10).map((match) => (
                  <ComboboxItem
                    id={match.value}
                    key={match.value}
                    textValue={match.label}
                  >
                    {match.leftSection && (
                      <div className="mr-2">{match.leftSection}</div>
                    )}
                    <div>
                      <HighlightText
                        textToHighlight={fieldState.inputValue}
                        value={match.label}
                        className="data-[user-value=true]:font-semibold"
                      />
                    </div>
                  </ComboboxItem>
                ))}
              </ComboboxSection>
            ) : (
              []
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  }
);

FlagCombobox.displayName = 'FlagCombobox';
