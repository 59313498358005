type DesktopProps = React.ComponentPropsWithoutRef<'svg'>;

export const Desktop: React.FC<DesktopProps> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 29H31.6C33.8402 29 34.9603 29 35.816 28.564C36.5686 28.1805 37.1805 27.5686 37.564 26.816C38 25.9603 38 24.8402 38 22.6V11.4C38 9.15979 38 8.03968 37.564 7.18404C37.1805 6.43139 36.5686 5.81947 35.816 5.43597C34.9603 5 33.8402 5 31.6 5H8.4C6.15979 5 5.03968 5 4.18404 5.43597C3.43139 5.81947 2.81947 6.43139 2.43597 7.18404C2 8.03968 2 9.15979 2 11.4V22.6C2 24.8402 2 25.9603 2.43597 26.816C2.81947 27.5686 3.43139 28.1805 4.18404 28.564C5.03968 29 6.15979 29 8.4 29H20ZM20 29V35M20 35H14M20 35H26"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.993 14.4411C19.2997 13.8137 18.472 13.5 17.51 13.5C16.4997 13.5 15.6375 13.846 14.9236 14.538C14.2096 15.2167 13.8594 16.0418 13.8594 17C13.8594 17.9582 14.2096 18.7833 14.9236 19.4753C15.6375 20.154 16.4997 20.5 17.51 20.5C18.472 20.5 19.2997 20.1863 19.993 19.5704C20.6863 20.1863 21.514 20.5 22.476 20.5C23.4864 20.5 24.3485 20.154 25.0625 19.4753C25.7764 18.7833 26.1401 17.9582 26.1401 17C26.1401 16.0418 25.7764 15.2167 25.0625 14.538C24.3485 13.846 23.4864 13.5 22.476 13.5C21.514 13.5 20.6863 13.8137 19.993 14.4411ZM17.1194 19.3289C17.4427 19.8612 17.7929 20.1274 18.1701 20.1274C18.6416 20.1274 18.8975 19.4221 18.8975 18.3042C18.8975 17.7453 18.8032 17.1331 18.6281 16.4544C18.453 15.7757 18.2105 15.1901 17.8737 14.6711C17.537 14.1521 17.1867 13.8859 16.823 13.8859C16.365 13.8859 16.136 14.4582 16.136 15.616C16.136 16.1882 16.2168 16.8137 16.3784 17.5057C16.5536 18.1844 16.7961 18.7966 17.1194 19.3289ZM22.0854 19.3289C22.4087 19.8612 22.7589 20.1274 23.1361 20.1274C23.6076 20.1274 23.8635 19.4221 23.8635 18.3042C23.8635 17.7453 23.7693 17.1331 23.5941 16.4544C23.419 15.7757 23.1765 15.1901 22.8398 14.6711C22.503 14.1521 22.1527 13.8859 21.789 13.8859C21.331 13.8859 21.102 14.4582 21.102 15.616C21.102 16.1882 21.1828 16.8137 21.3445 17.5057C21.5196 18.1844 21.7621 18.7966 22.0854 19.3289Z"
        fill="currentColor"
      />
    </svg>
  );
};
