import { useTranslation } from 'react-i18next';

import { AuthForm } from '../../auth-form/auth-form';

export type OnboardingStepAuthSignupProps = {
  goToNextSubStepOrStep: (email: string) => void;
};

export const OnboardingStepAuthSignup: React.FC<
  OnboardingStepAuthSignupProps
> = ({ goToNextSubStepOrStep }: OnboardingStepAuthSignupProps) => {
  const { t } = useTranslation();

  return (
    <section className="mt-8 flex flex-col gap-10 sm:mt-20">
      <div className="flex flex-col gap-2">
        <h1 className="text-3.5xl font-bold">
          {t('auth.signup.start_by_creating_your_account')}
        </h1>
        <p className="text-sm leading-5.5 text-gray-700">
          {t('onboarding.signup.subtitle')}
        </p>
      </div>

      <AuthForm
        showLoginWithPassword={false}
        success={(result) => {
          goToNextSubStepOrStep(encodeURIComponent(result.email));
        }}
      />
    </section>
  );
};
