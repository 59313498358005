import { Key, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Combobox, {
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxPopover,
  ComboboxSection,
  HighlightText,
  InputContainer,
} from '@appchoose/combobox';
import { FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { useQueryClient } from '@tanstack/react-query';

import { useSearchCouriersQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { getCountryISO2 } from '../../utils/country';
import { OnboardingStepShippingDeliveryServiceForm } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import { ShippingServiceCheckbox } from './shipping-service-checkbox';

type ShippingServiceFieldsProps = {
  shippingCountryCode: string;
};

export const ShippingServiceFields: React.FC<ShippingServiceFieldsProps> = ({
  shippingCountryCode,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [searchCourier, setSearchCourier] = useState('');

  const form = useFormContext<OnboardingStepShippingDeliveryServiceForm>();
  const { fields, append } = useFieldArray({
    control: form.control,
    name: 'services',
  });

  const { data: couriers, isLoading } = useSearchCouriersQuery(
    {
      input: {
        name: searchCourier,
      },
    },
    {
      select: (data) =>
        data.searchCouriers.map((courier) => ({
          ...courier,
          supportedCountries: courier.supportedCountries.map(
            (country) => getCountryISO2(country) ?? ''
          ),
        })),
      enabled: !!searchCourier,
    }
  );

  const onSelectionChange = (key: Key | null) => {
    const foundCourier = couriers?.find((f) => f.slug === key) ?? null;
    if (!foundCourier) return;

    setSearchCourier('');
    append({
      ...foundCourier,
      selected: true,
    });
    queryClient.resetQueries({
      queryKey: ['couriers'],
    });
    track('SelectCarrier', {
      selected_carrier: foundCourier.slug,
    });
    if (!foundCourier.supportedCountries.includes(shippingCountryCode)) {
      track('CarrierCountryWarning', {
        warning_message: 'carrier_not_matching_country',
      });
    }
  };

  form.watch('services');

  const isPickupCarrierSelected = form
    .getValues('services')
    ?.filter((service) => service.selected)
    .map((service) => service.slug)
    .some((slug) => slug.startsWith('mondialrelay'));

  const isUnsupportedCountryCarrierSelected = form
    .getValues('services')
    ?.filter((service) => service.selected)
    .map((service) => service.supportedCountries)
    .some(
      (supportedCountries) => !supportedCountries.includes(shippingCountryCode)
    );

  const filteredCouriers = couriers?.filter(
    (courier) =>
      !form
        .getValues('services')
        .map((service) => service.slug)
        ?.includes(courier.slug)
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <ul className="flex flex-wrap gap-4">
          {fields.map((arrayField, index) => (
            <li key={arrayField.id}>
              <FormField
                control={form.control}
                name={`services.${index}.selected`}
                rules={{
                  validate: {
                    atLeastOneSelected: () =>
                      form
                        .getValues()
                        .services.some((service) => service.selected),
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ShippingServiceCheckbox
                        {...field}
                        value=""
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        warning={
                          !arrayField.supportedCountries.includes(
                            shippingCountryCode
                          )
                        }
                      >
                        <div className="flex shrink-0 items-center gap-2">
                          <img src={arrayField.logoUrl} className="size-8" />
                          <span className="text-sm font-semibold text-gray-900">
                            {arrayField.name}
                          </span>
                        </div>
                      </ShippingServiceCheckbox>
                    </FormControl>
                  </FormItem>
                )}
              />
            </li>
          ))}
        </ul>
        <ul className="flex flex-col gap-2">
          {isPickupCarrierSelected ? (
            <li className="flex gap-1 text-xs text-orange-600">
              <Icon icon="information" className="shrink-0" />
              <span>
                {t('onboarding.shipping.carrier.pickup_carrier_warning')}
              </span>
            </li>
          ) : null}
          {isUnsupportedCountryCarrierSelected ? (
            <li className="flex gap-1 text-xs text-orange-600">
              <Icon icon="information" className="shrink-0" />
              <span>
                {t(
                  'onboarding.shipping.carrier.unsupported_country_carrier_warning'
                )}
              </span>
            </li>
          ) : null}

          {form.formState.errors.services ? (
            <li className="text-xs text-red-600">
              {t(
                'onboarding.shipping.carrier.validation_errors.at_least_one_selected'
              )}
            </li>
          ) : null}
        </ul>
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-xs font-semibold uppercase tracking-wider text-gray-700">
          {t('onboarding.shipping.carrier.label')}
        </label>

        <Combobox
          inputValue={searchCourier}
          onSelectionChange={onSelectionChange}
          onInputChange={(value: string) => {
            setSearchCourier(value);
            if (value !== '') {
              track('SearchCarrier', {
                search_term: value,
              });
            }
          }}
          items={filteredCouriers}
        >
          <InputContainer>
            <div className="absolute inset-y-0 left-0 flex items-center pl-2">
              <Icon icon="search" />
            </div>

            <ComboboxInput
              className="truncate pl-7"
              placeholder={t('order.tracking.fields.carrier.placeholder')}
              autoComplete="off"
            />
          </InputContainer>
          {searchCourier !== '' ? (
            <ComboboxPopover className="!max-h-80">
              <ComboboxList
                renderEmptyState={() =>
                  isLoading ? (
                    <ComboboxEmpty>
                      {t('onboarding.shipping.carrier.loading')}
                    </ComboboxEmpty>
                  ) : (
                    <ComboboxEmpty className="flex flex-col items-start gap-4 px-4 py-6 text-gray-900">
                      <div className="text-sm leading-5.5">
                        {t('order.tracking.fields.carrier.no_result')}
                      </div>
                      <div className="flex items-center gap-2 text-gray-700">
                        <Icon icon="information" />
                        <div className="text-sm leading-5.5">
                          {t(
                            'onboarding.shipping.carrier.carrier_not_found_contact_am'
                          )}
                        </div>
                      </div>
                    </ComboboxEmpty>
                  )
                }
              >
                {filteredCouriers?.length ? (
                  <ComboboxSection>
                    {filteredCouriers.map((courier) => (
                      <ComboboxItem
                        id={courier.slug}
                        key={courier.slug}
                        textValue={courier.name}
                      >
                        {courier.logoUrl && (
                          <div className="mr-2 shrink-0">
                            <img
                              src={courier.logoUrl}
                              alt=""
                              className="size-5"
                            />
                          </div>
                        )}
                        <div>
                          <HighlightText
                            textToHighlight={searchCourier}
                            value={courier.name}
                            className="data-[user-value=true]:font-semibold"
                          />
                        </div>
                      </ComboboxItem>
                    ))}
                  </ComboboxSection>
                ) : null}
              </ComboboxList>
            </ComboboxPopover>
          ) : null}
        </Combobox>
      </div>
    </>
  );
};

ShippingServiceFields.displayName = 'ShippingServiceFields';
